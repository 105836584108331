import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ApiService } from './core/services/api.service';
import { UsersComponent } from './components/users/users.component';
import { AddTrainingComponent } from './components/users/add-training/add-training.component';
import { ViewTrainingComponent } from './components/users/view-training/view-training.component';
import { AddWorkoutComponent } from './components/users/add-workout/add-workout.component';
import { ViewWorkoutsComponent } from './components/users/view-workouts/view-workouts.component';
import { CommonService } from './core/services/common.service';
import { SweetAlertService } from './core/services/sweetalert.service';
import { MediaComponent } from './components/media/media.component';
import { AddMediaComponent } from './components/media/add-media/add-media.component';
import { EditMediaComponent } from './components/media/edit-media/edit-media.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ViewContactUsComponent } from './components/contact-us/view-contact-us/view-contact-us.component';
import { CanActivateThisRoute } from './core/guard/router.guard';
import { UserInfoComponent } from './components/users/user-info/user-info.component';
import { UserStatsComponent } from './components/users/user-stats/user-stats.component';
import { ViewPlansComponent } from './components/users/view-plans/view-plans.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { ViewBookingComponent } from './components/bookings/view-booking/view-booking.component';
import { ClassesComponent } from './components/classes/classes.component';
import { ViewClassComponent } from './components/classes/view-class/view-class.component';
import { EncryptionService } from './core/services/encryption.service';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    UsersComponent,
    AddTrainingComponent,
    ViewTrainingComponent,
    AddWorkoutComponent,
    ViewWorkoutsComponent,
    MediaComponent,
    AddMediaComponent,
    EditMediaComponent,
    ContactUsComponent,
    ViewContactUsComponent,
    UserInfoComponent,
    UserStatsComponent,
    ViewPlansComponent,
    BookingsComponent,
    ViewBookingComponent,
    ClassesComponent,
    ViewClassComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DataTablesModule,
    NgbModule,
    ReactiveFormsModule,
    FullCalendarModule
  ],
  providers: [ApiService, CommonService, CanActivateThisRoute, SweetAlertService, EncryptionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
