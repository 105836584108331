import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-media',
  templateUrl: './edit-media.component.html',
  styleUrls: ['./edit-media.component.css']
})
export class EditMediaComponent implements OnInit {

  formData = new FormData();

  editMediaForm: FormGroup;
  submitted = false;

  constructor( private api: ApiService, private route: ActivatedRoute, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.editMediaForm = this.formBuilder.group({
      id: 0,
      name: [null, [Validators.required, Validators.minLength(3)]],
      uri: null,
      path: null,
      type: null
    });
    this.route.params.subscribe(params => {
      this.editMediaForm.controls['id'].setValue(+params['id'])

      this.api.getSpecificMedia(this.editMediaForm.get('id').value).subscribe(res => {
        var response: any = res;
        response = response.success

        this.editMediaForm.controls['name'].setValue(response.name)
        this.editMediaForm.controls['uri'].setValue(response.uri)
        this.editMediaForm.controls['type'].setValue(response.type)
        this.editMediaForm.controls['path'].setValue(response.path)
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    });
  }

  get f(): any { return this.editMediaForm.controls; }

  OnYoutubeLinkChange(value) {
    try {
      var video_id = value.split('v=')[1];
      var ampersandPosition = video_id.indexOf('&');
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      this.editMediaForm.controls['uri'].setValue('https://www.youtube.com/embed/' + video_id)
    } catch (err) {
      if (this.editMediaForm.get('uri').value) {
        alert('Enter valid youtube link')
      }
    }
  }

  OnChooseFile(e) {
    let elem = e.target;
    this.formData.set('attachments[]', elem.files[0]);
  }

  OnSubmit() {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Media will be updated', 'warning').then((result) => {
      if (result.value) {
        this.formData.append('data', JSON.stringify(this.editMediaForm.value));
        this.api.postUpdateMedia(this.formData).subscribe(res => {
          this.swa.ShowAlert('Success', 'Media updated successfully', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }
}
