import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Countries from '../../../assets/countries.json';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: any;

  addUserForm: FormGroup;
  changePasswordForm: FormGroup;
  changePassword_submitted = false;
  submitted = false;

  Countries;

  constructor(private api: ApiService, private modalService: NgbModal, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    // this.Countries = Countries.sort((a, b) => (a.callingCodes[0] > b.callingCodes[0]) ? 1 : -1)
    this.Countries = Countries.sort()
    this.changePasswordForm = this.formBuilder.group({
      email: null,
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      confirm_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]]
    });

    this.addUserForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      firstName: [null, [Validators.required, Validators.minLength(3)]],
      lastName: [null, [Validators.required, Validators.minLength(3)]],
      countryCode: ['961', [Validators.required]],
      phoneNumber: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]]
    });
    this.api.getAllUsers().subscribe(res => {
      var response: any = res;
      this.users = response.success;
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  get f(): any { return this.addUserForm.controls; }
  get change_f(): any { return this.changePasswordForm.controls; }

  open(content, item) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    if (item) {
      this.changePasswordForm.controls['email'].setValue(item.email);
    }
  }

  OnCountryCodeOptionClicked(value) {
    this.addUserForm.controls['countryCode'].setValue(value);
  }

  OnChangePassword() {
    this.changePassword_submitted = true;
    if (this.changePasswordForm.valid) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'Password will be updated', 'warning').then((result) => {
        if (result.value) {
          this.api.postChangeUserPasswordByAdmin(this.changePasswordForm.value).subscribe(res => {
            var response: any = res;
            if (response.error) {
              this.swa.ShowAlert('Error', response.error, 'error')
            } else {
              this.swa.ShowAlert('Success', 'Password updated successfully', 'success')
            }
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      });
    }
  }

  OnAddUser() {
    this.submitted = true;
    if (this.addUserForm.valid) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'User will be added', 'warning').then((result) => {
        if (result.value) {
          this.api.postSignup(this.addUserForm.value).subscribe(res => {
            this.modalService.dismissAll();
            this.api.getAllUsers().subscribe(res => {
              var response: any = res;
              this.users = response.success;
            }, (err) => {
              this.api.showLoader = false;
              this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
            }, () => {
              this.api.showLoader = false;
            })
            this.swa.ShowAlert('Success', 'User added successfully', 'success')
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }

  onDeleteUser(id) {

    this.swa.ShowAlertConfirmation('Are you sure?', 'User will be deleted', 'warning').then((result) => {
      if (result.value) {
        var data = { id: id }
        this.api.postDeleteUser(data).subscribe(res => {
          const index = this.users.findIndex((e) => e.id === id);
          this.users.splice(index, 1)
          this.swa.ShowAlert('Success', 'User deleted', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }
}
