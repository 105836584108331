import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {
  data: any;
  constructor(private swa: SweetAlertService, private api: ApiService, public cms: CommonService) { }

  ngOnInit() {
    this.api.getBookingUsers().subscribe(res => {
      var response: any = res;
      this.cms.bookingUsers = response.success
    }, err => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => { 
      this.api.showLoader = false;
    })
  }

}
