import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-view-plans',
  templateUrl: './view-plans.component.html',
  styleUrls: ['./view-plans.component.css']
})
export class ViewPlansComponent implements OnInit {

  userId;
  data: any;

  constructor( private route: ActivatedRoute, private api: ApiService, private swa: SweetAlertService) { }

  getUserRegisteredPlans(userId) {
    this.api.getUserRegisteredPlans(userId).subscribe(res => {
      var response: any = res;
      this.data = response.success;
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = +params['id'];
      this.getUserRegisteredPlans(this.userId);
    });
  }

  confirmPlanStatus(item: any) {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Plan will be confirmed!', 'warning').then((result) => {
      if (result.value) {
        var data = { id: item.id, userId: item.userId, planId: item.planId };
        this.api.postsendPlanConfirmation(data).subscribe(res => {
          this.getUserRegisteredPlans(this.userId)
          this.swa.ShowAlert('Success', 'Plan confirmed', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }

  cancelPlanStatus(id) {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Plan will be canceled!', 'warning').then((result) => {
      if (result.value) {
        var data = { id: id };
        this.api.postcancelPlanRequest(data).subscribe(res => {
          const index = this.data.findIndex(x => x.id === id);
          if (index !== -1) {
            this.data[index].status = 0
          }
          this.swa.ShowAlert('Success', 'Plan canceled', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }
}
