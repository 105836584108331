import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { OptionsInput } from '@fullcalendar/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css']
})

export class ClassesComponent implements OnInit {
  options: OptionsInput;
  addClassForm: FormGroup;
  submitted = false;

  constructor( public cms: CommonService, private api: ApiService, private modalService: NgbModal, private swa: SweetAlertService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {

    this.getAllClasses();

    this.addClassForm = this.formBuilder.group({
      title: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(30)]],
      startDate: [null, [Validators.required]],
      startDate_time: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      endDate_time: [null, [Validators.required]],
    });

    this.options = {
      header: {
        left: 'prev, next, today',
        center: 'title',
        right: 'dayGridMonth, timeGridWeek, timeGridDay',
      },
      allDayText: 'Time',
      minTime: "08:00:00",
      maxTime: "24:00:00",
      defaultView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin]
    };

  }

  get f(): any { return this.addClassForm.controls; }

  getAllClasses() {
    this.api.getAllClasses().subscribe(res => {
      var response: any = res;
      this.cms.classesCalendarEvents = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  eventClick(e) {
    this.router.navigateByUrl('/home/view-class/' + e.event.id)
  }

  OnAddClass() {
    this.submitted = true;
    if (this.addClassForm.valid) {

      this.swa.ShowAlertConfirmation('Are you sure?', 'Class will be added', 'warning').then((result) => {
        if (result.value) {
          var startDateTime = this.cms.getDateTimeSTRING(this.addClassForm.get('startDate').value, this.addClassForm.get('startDate_time').value)
          var endDateTime = this.cms.getDateTimeSTRING(this.addClassForm.get('endDate').value, this.addClassForm.get('endDate_time').value)

          if (new Date(startDateTime) >= new Date(endDateTime)) {
            this.swa.ShowAlert('Error', 'Start date must be greater than end date', 'error')
            return 0
          }

          var obj = { title: this.addClassForm.get('title').value, startDate: startDateTime, endDate: endDateTime };

          this.api.postAddClass(obj).subscribe(res => {
            this.getAllClasses();
            this.modalService.dismissAll()
            this.swa.ShowAlert('Success', 'Class added successfully', 'success')
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }
}
