import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public api: ApiService, public cms: CommonService) { }

  ngOnInit() {

  }
  OnLogout() {
    this.cms.deleteCookie('authToken');
    this.api.Is_Logged_In.next(false);
    document.location.href = "/"
  }
}
