import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  nutrition: any;
  fitness: any;

  isNutrition = false;

  constructor( private route: ActivatedRoute, private api: ApiService, private swa: SweetAlertService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.api.getNutritionFitnessSpecificUser(+params['id']).subscribe(res => {
        var response: any = res;
        if (response.success) {
          response = response.success
          this.nutrition = response.nutrition;
          this.fitness = response.fitness;
        }
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    });
  }

  onSelectChange(value) {
    if (value === 'nutrition') {
      this.isNutrition = true;
    } else {
      this.isNutrition = false
    }
  }
}
