import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  data: any
  constructor(private api: ApiService, private swa: SweetAlertService) { }

  ngOnInit() {
    this.api.getAllContactUs().subscribe(res => {
      var response: any = res;
      this.data = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  onDeleteMessage(id) {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Message will be deleted', 'warning').then((result) => {
      if (result.value) {
        var data = { id: id }
        this.api.postDeleteContactUs(data).subscribe(res => {
          const index = this.data.findIndex((e) => e.id === id);
          this.data.splice(index, 1)
          this.swa.ShowAlert('Success', 'Message deleted', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }

}
