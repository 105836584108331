import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;

  constructor( private api: ApiService, private router: Router, private cms: CommonService, private swa: SweetAlertService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    if (this.api.AuthToken) {
      this.api.Is_Logged_In.next(true);
      this.router.navigateByUrl('home/users')
      return 0;
    }
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]]
    });
  }

  get f(): any { return this.loginForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.loginForm.valid) {
      this.api.postLogin(this.loginForm.value).subscribe(res => {
        var response: any = res;
        response = response.success
        if (response.role === 'admin') {
          this.api.AuthToken = response.token;
          this.api.Is_Logged_In.next(true)
          this.cms.activeUsers = response.counter;
          this.cms.setCookie('authToken', response.token);
          this.router.navigateByUrl('home/users')
        } else {
          this.api.Is_Logged_In.next(false)
          this.swa.ShowAlert('Error', 'Unauthorized', 'error')
        }
      }, (err) => {
        this.api.Is_Logged_In.next(false)
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }
  }
}
