import { Component, OnInit } from '@angular/core';
import { ApiService } from './core/services/api.service';
import { Router } from '@angular/router';
import { CommonService } from './core/services/common.service';
import { SweetAlertService } from './core/services/sweetalert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(public api: ApiService, private router: Router, public cms: CommonService, private swa: SweetAlertService) {
    if (this.cms.getCookie('authToken')) {
      this.api.AuthToken = this.cms.getCookie('authToken');
      this.api.getAttempt().subscribe(res => {
        var response: any = res;
        response = response.success
        this.api.AuthToken = response.token;
        this.cms.setCookie('authToken', response.token);
        this.api.Is_Logged_In.next(true);
        this.cms.activeUsers = response.counter;
      }, err => {
        this.cms.deleteCookie('authToken')
        this.api.showLoader = false;
        this.api.Is_Logged_In.next(false);
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }
    else {
      this.api.showLoader = false;
      this.api.Is_Logged_In.next(false)
      this.router.navigateByUrl('login')
    }
  }

  ngOnInit() {
    this.initializeCookies();
  }

  initializeCookies() {
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: 'This website use cookies to ensure you get the best experience on our website',
        // dismiss: this.cookieDismiss,
        // link: this.cookieLinkText,
        // href: environment.Frontend + "/dataprivacy" 
      }
    });
  }
}
