import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
    SentTrainingItem: any;
    CopyWorkouts: any = [];
    activeUsers;
    bookingUsers: any;
    classesCalendarEvents: any;

    setCookie(cname, cvalue) {
        const exdays = 30;
        var secure = '';
        if (window.location.protocol === 'https') {
            secure = "secure;";
        }
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + secure + expires + ";path=/";
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    deleteCookie(cname) {
        document.cookie = cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
    }


    getDateTimeJSON(date) {
        var _date = new Date(date);
        var new_date = { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() };
        var new_time = { hour: _date.getHours(), minute: _date.getMinutes() };
        return { date: new_date, time: new_time }

    }

    checkLower(data) {
        if (Number(data) < 10) {
            return "0" + data;
        }
        return data
    }

    getDateTimeSTRING(date, time) {
        var new_date = this.checkLower(date.year) + "-" + this.checkLower(date.month) + "-" + this.checkLower(date.day)
        var new_time = this.checkLower(time.hour) + ":" + this.checkLower(time.minute) + ":00";

        return new_date + " " + new_time
    }
}
