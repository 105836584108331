import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { AddTrainingComponent } from './components/users/add-training/add-training.component';
import { ViewTrainingComponent } from './components/users/view-training/view-training.component';
import { AddWorkoutComponent } from './components/users/add-workout/add-workout.component';
import { ViewWorkoutsComponent } from './components/users/view-workouts/view-workouts.component';
import { MediaComponent } from './components/media/media.component';
import { AddMediaComponent } from './components/media/add-media/add-media.component';
import { EditMediaComponent } from './components/media/edit-media/edit-media.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ViewContactUsComponent } from './components/contact-us/view-contact-us/view-contact-us.component';
import { CanActivateThisRoute } from './core/guard/router.guard';
import { UserInfoComponent } from './components/users/user-info/user-info.component';
import { UserStatsComponent } from './components/users/user-stats/user-stats.component';
import { ViewPlansComponent } from './components/users/view-plans/view-plans.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { ViewBookingComponent } from './components/bookings/view-booking/view-booking.component';
import { ClassesComponent } from './components/classes/classes.component';
import { ViewClassComponent } from './components/classes/view-class/view-class.component';

const routes: Routes = [
  { path: '', redirectTo: '/home/users', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent, children:
      [
        { path: 'users', component: UsersComponent, canActivate: [CanActivateThisRoute] },
        { path: 'media', component: MediaComponent, canActivate: [CanActivateThisRoute] },
        { path: 'contact-us', component: ContactUsComponent, canActivate: [CanActivateThisRoute] },
        { path: 'classes', component: ClassesComponent, canActivate: [CanActivateThisRoute] },
        { path: 'view-class/:id', component: ViewClassComponent, canActivate: [CanActivateThisRoute] },
        { path: 'bookings', component: BookingsComponent, canActivate: [CanActivateThisRoute] },
        { path: 'view-booking/:id', component: ViewBookingComponent, canActivate: [CanActivateThisRoute] },
        { path: 'view-contact-us/:id', component: ViewContactUsComponent, canActivate: [CanActivateThisRoute] },
        { path: 'add-media', component: AddMediaComponent, canActivate: [CanActivateThisRoute] },
        { path: 'edit-media/:id', component: EditMediaComponent, canActivate: [CanActivateThisRoute] },
        { path: 'add-training/:id', component: AddTrainingComponent, canActivate: [CanActivateThisRoute] },
        { path: 'view-training/:id', component: ViewTrainingComponent, canActivate: [CanActivateThisRoute] },
        { path: 'view-plans/:id', component: ViewPlansComponent, canActivate: [CanActivateThisRoute] },
        { path: 'user-info/:id', component: UserInfoComponent, canActivate: [CanActivateThisRoute] },
        { path: 'user-stats/:id', component: UserStatsComponent, canActivate: [CanActivateThisRoute] },
        { path: 'add-workout/:id', component: AddWorkoutComponent, canActivate: [CanActivateThisRoute] },
        { path: 'view-workouts/:id', component: ViewWorkoutsComponent, canActivate: [CanActivateThisRoute] },
        { path: 'login', component: LoginComponent },
      ],
  },
  { path: '**', redirectTo: '/home/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
