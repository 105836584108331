import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-training',
  templateUrl: './add-training.component.html',
  styleUrls: ['./add-training.component.css']
})
export class AddTrainingComponent implements OnInit {

  addTrainingForm: FormGroup;
  submitted = false;

  training_media: any;

  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.addTrainingForm = this.formBuilder.group({
      userId: 0,
      title: [null, [Validators.required, Validators.minLength(3)]],
      date: [null, [Validators.required, Validators.minLength(3)]],
      idMedia: null,
      note: [null, [Validators.minLength(3)]],
      status: ['pending'],
    });

    this.route.params.subscribe(params => {
      this.addTrainingForm.controls['userId'].setValue(+params['id']);
    });

    this.api.getAllTrainingMedia().subscribe(res => {
      var response: any = res;
      this.training_media = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  get f(): any { return this.addTrainingForm.controls; }

  OnMediaDropdownChanged(value) {
    this.addTrainingForm.controls['idMedia'].setValue(+value);
  }

  onSubmit() {

    this.submitted = true;

    if (this.addTrainingForm.valid) {
      const date: any = this.addTrainingForm.controls['date'].value;
      var new_date = date.year + '-' + date.month + '-' + date.day;
      this.addTrainingForm.controls['date'].setValue(new_date);

      this.swa.ShowAlertConfirmation('Are you sure?', 'Training will be added', 'warning').then((result) => {
        if (result.value) {
          this.api.postAddTraining(this.addTrainingForm.value).subscribe(res => {
            var response: any = res;
            response = response.success
            this.router.navigateByUrl('/home/add-workout/' + response.id)
            this.swa.ShowAlert('Success', 'Training added successfully', 'success')
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }
}
