import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.css']
})
export class UserStatsComponent implements OnInit {

  data: any;

  constructor( private route: ActivatedRoute, private api: ApiService, private swa: SweetAlertService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.api.getSpecificAssessments(+params['id']).subscribe(res => {
        var response: any = res;
        this.data = response.success
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    });
  }
}
