import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.css']
})
export class ViewBookingComponent implements OnInit {

  booking: any;

  constructor(private api: ApiService, private cms: CommonService, private route: ActivatedRoute, private router: Router, private swa: SweetAlertService) { }

  ngOnInit() {
    if (!this.cms.bookingUsers) {
      this.api.getBookingUsers().subscribe(res => {
        var response: any = res;
        this.cms.bookingUsers = response.success
        this.getBooking();
      }, err => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    } else {
      this.getBooking()
      this.api.showLoader = false;
    }
  }

  getBooking() {
    this.route.params.subscribe(params => {
      this.booking = this.cms.bookingUsers.find(x => x.bookingId === +params.id);
      if (!this.booking) {
        this.router.navigateByUrl('users')
      }
    });
  }

  onSubmit() {
    this.swa.ShowAlertConfirmation('Are you sure?', 'This class will be booked!', 'warning').then((result) => {
      if (result.value) {
        const data = { classId: this.booking.class.id, bookingId: this.booking.bookingId };
        this.api.postConfirmBooking(data).subscribe(res => {
          const index = this.cms.bookingUsers.findIndex((e) => e.id === this.booking.id);
          this.cms.bookingUsers.splice(index, 1)
          window.history.back();
          this.swa.ShowAlert('Success', 'Class booked successfully', 'success')
        }, (err) => {
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        })
      }
    })
  }
}
