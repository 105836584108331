import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-training',
  templateUrl: './view-training.component.html',
  styleUrls: ['./view-training.component.css']
})
export class ViewTrainingComponent implements OnInit {

  all_trainings: any;
  trainings_lookups: any;
  trainings_lookup_active_id: any;
  training_media: any;


  editTrainingForm: FormGroup;
  submitted = false;

  constructor(private api: ApiService, private route: ActivatedRoute, private modalService: NgbModal, private cms: CommonService, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.editTrainingForm = this.formBuilder.group({
      id: 0,
      userId: 0,
      title: [null, [Validators.required, Validators.minLength(3)]],
      date: [null, [Validators.required]],
      idMedia: null,
      note: [null, [Validators.minLength(3)]],
      status: 'pending',
    });
    this.route.params.subscribe(params => {
      this.editTrainingForm.controls['userId'].setValue(+params['id']);
      this.getAllTrainings()
      this.getAllTrainings(+params['id'])
    });

    this.api.getAllTrainingMedia().subscribe(res => {
      var response: any = res;
      this.training_media = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })

  }

  getAllTrainings(id = 1) {
    this.api.getAllTrainings(id).subscribe(res => {
      var response: any = res;
      if (id == 1 && this.editTrainingForm.controls['userId'].value != 1) {
        this.trainings_lookups = response.success
        if (response.success.length > 0) {
          this.trainings_lookup_active_id = response.success[0].id
        }
      } else {
        this.all_trainings = response.success
      }
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    });
  }

  get f(): any { return this.editTrainingForm.controls; }

  OnMediaDropdownChanged(value) {
    this.editTrainingForm.controls['idMedia'].setValue(+value);
  }

  OnDropdownChange(value) {
    this.trainings_lookup_active_id = value
  }

  OnAddTrainingLookup() {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Training will be added with workouts', 'warning').then((result) => {
      if (result.value) {
        var data = { id: this.trainings_lookup_active_id, userId: this.editTrainingForm.controls['userId'].value }
        this.api.postCloneTraining(data).subscribe(res => {
          this.getAllTrainings(this.editTrainingForm.controls['userId'].value)
          this.swa.ShowAlert('Success', 'Training deleted', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }

  open(content, item) {
    this.editTrainingForm.controls['id'].setValue(item.id);
    this.editTrainingForm.controls['title'].setValue(item.title);
    var date = item.date.split('-');
    date = {
      year: parseInt(date[0]),
      month: parseInt(date[1]),
      day: parseInt(date[2]),
    }
    this.editTrainingForm.controls['date'].setValue(date);
    this.editTrainingForm.controls['idMedia'].setValue(item.idMedia);
    this.editTrainingForm.controls['note'].setValue(item.note);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  SendTrainingObject(item) {
    this.cms.SentTrainingItem = item
  }

  OnUpdate() {

    this.submitted = true;
    if (this.editTrainingForm.valid) {
      var date: any = this.editTrainingForm.controls['date'].value;
      var new_date = date.year + '-' + date.month + '-' + date.day;
      this.editTrainingForm.controls['date'].setValue(new_date);

      this.swa.ShowAlertConfirmation('Are you sure?', 'Training will be updated', 'warning').then((result) => {
        if (result.value) {
          this.api.postUpdateTraining(this.editTrainingForm.value).subscribe(res => {
            this.modalService.dismissAll();
            this.submitted = false;
            this.swa.ShowAlert('Success', 'Training updated successfully', 'success')
            var id = this.editTrainingForm.get('id').value
            const index = this.all_trainings.findIndex((e) => e.id === id);
            this.all_trainings[index] = this.editTrainingForm.value;
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }

  onCopyWorkouts(id) {
    this.api.getTrainingWorkouts(id).subscribe(res => {
      var response: any = res;
      this.cms.CopyWorkouts = response.success;
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  onDeleteTraining(id) {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Training will be deleted', 'warning').then((result) => {
      if (result.value) {
        var data = { id: id }
        this.api.postDeleteTraining(data).subscribe(res => {
          const index = this.all_trainings.findIndex((e) => e.id === id);
          this.all_trainings.splice(index, 1)
          this.swa.ShowAlert('Success', 'Training deleted', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }
}
