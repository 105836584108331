import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-view-contact-us',
  templateUrl: './view-contact-us.component.html',
  styleUrls: ['./view-contact-us.component.css']
})
export class ViewContactUsComponent implements OnInit {

  data: any = {};

  constructor( private api: ApiService, private route: ActivatedRoute, private swa: SweetAlertService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.api.getSpecificContactUs(+params['id']).subscribe(res => {
        var response: any = res;
        this.data = response.success
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    });

  }
}
