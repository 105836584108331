import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  data: any;
  constructor( private api: ApiService, private swa: SweetAlertService) { }

  ngOnInit() {
  }

  OnDropdownChange(value) {
    if (value === 1) {
      this.api.getAllTrainingMedia().subscribe(res => {
        var response: any = res;
        this.data = response.success
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    } else if (value === 2) {
      this.api.getAllWorkoutMedia().subscribe(res => {
        var response: any = res;
        this.data = response.success
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    } else {
      this.api.showLoader = false
      return 0
    }
  }

  onDeleteMedia(id) {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Media will be deleted', 'warning').then((result) => {
      if (result.value) {
        var data = { id: id }
        this.api.postDeleteMedia(data).subscribe(res => {
          const index = this.data.findIndex((e) => e.id === id);
          this.data.splice(index, 1)
          this.swa.ShowAlert('Success', 'Media deleted', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }

}
