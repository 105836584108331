import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-workouts',
  templateUrl: './view-workouts.component.html',
  styleUrls: ['./view-workouts.component.css']
})
export class ViewWorkoutsComponent implements OnInit {

  training_id: any;
  training_status: any;
  workout_media: any;

  RepsArray = new Array(100)
  nbRepsPerSetArray = [];
  activeSetNumberIndex = 0;

  postWorkout: {
    before: '',
    after: ''
    difficult: '',
    comment: ''
  }

  header = {
    title: '',
    date: '',
    note: '',
    uri: ''
  }

  data: any = [];
  order_arr: any = [];
  modaVideo: SafeHtml;

  PostWorkoutLevel = [
    '-',
    'Tired - 1',
    'Tired - 2',
    'Tired - 3',
    'Just Fine - 4',
    'Just Fine - 5',
    'Just Fine - 6',
    'Great - 7',
    'Great - 8',
    'Great - 9',
    'Excellent - 10',
  ]

  editWorkoutForm: FormGroup;
  submitted = false;

  constructor(private api: ApiService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal, private swa: SweetAlertService) { }

  ngOnInit() {

    this.api.getAllWorkoutMedia().subscribe(res => {
      var response: any = res;
      this.workout_media = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })

    this.editWorkoutForm = this.formBuilder.group({
      id: [null, [Validators.required]],
      title: [null, [Validators.required]],
      idTraining: [null, [Validators.required]],
      setRest: [null, [Validators.required]],
      exerciceRest: [null, [Validators.required]],
      nbSets: [null, [Validators.required]],
      nbRepsPerSet: null,
      workoutTime: 0,
      minRepetitions: 0,
      maxRepetitions: 0,
      minTime: 0,
      maxTime: 0,
      maximum: null,
      color: null,
      weight: 0,
      idMedia: 0,
    });

    this.route.params.subscribe(params => {
      this.training_id = +params['id'];
      this.editWorkoutForm.controls['idTraining'].setValue(+params['id']);

      this.api.getSpecificTraining(this.training_id).subscribe(res => {
        var response: any = res;
        response = response.success
        this.header.title = response.title;
        this.header.date = response.date;
        this.header.note = response.note;
        this.header.uri = response.uri;
        this.training_status = response.status;

        switch (this.training_status) {
          case 'done':
            this.getHistoryTrainingWorkout();
            break;
          case 'pending':
            this.geTrainingWorkouts()
            break;
          default:
            break;
        }
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    });
  }

  get f(): any { return this.editWorkoutForm.controls; }

  getHistoryTrainingWorkout() {
    this.api.getHistoryTrainingWorkouts(this.training_id).subscribe(res => {
      var response: any = res;
      response = response.success;
      this.data = response.result;
      for (let i in this.data) {
        this.data[i].new_exerciceRest = this.secondToMinutes(this.data[i].exerciceRest);
        this.data[i].new_setRest = this.secondToMinutes(this.data[i].setRest);

        this.data[i].workout_order = +i + 1
        if (this.data[i].new_minTime !== 0) {
          this.data[i].new_minTime = this.secondToMinutes(this.data[i].minTime);
        }
        if (this.data[i].new_maxTime !== 0) {
          this.data[i].new_maxTime = this.secondToMinutes(this.data[i].maxTime);
        }
        this.order_arr.push({ id: this.data[i].id, workout_order: this.data[i].workout_order })
      }
      this.postWorkout = response.postWorkout;
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  geTrainingWorkouts() {
    this.order_arr = [];
    this.api.getTrainingWorkouts(this.training_id).subscribe(res => {
      var response: any = res;
      this.data = response.success;
      for (let i in this.data) {
        this.data[i].new_exerciceRest = this.secondToMinutes(this.data[i].exerciceRest);
        this.data[i].new_setRest = this.secondToMinutes(this.data[i].setRest);
        this.data[i].workout_order = +i + 1

        if (this.data[i].new_minTime !== 0) {
          this.data[i].new_minTime = this.secondToMinutes(this.data[i].minTime);
        }
        if (this.data[i].new_maxTime !== 0) {
          this.data[i].new_maxTime = this.secondToMinutes(this.data[i].maxTime);
        }
        this.order_arr.push({ id: this.data[i].id, workout_order: this.data[i].workout_order })
      }
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  secondToMinutes(time) {
    if (time <= 60) {
      return time + " secs";
    }
    else {
      var minutes = Math.floor(time / 60);
      var seconds = time - minutes * 60;
      if (seconds === 0) {
        return minutes + " min ";
      } else {
        return minutes + " min " + seconds + " secs";
      }
    }
  }

  onOrderChanged(index, value) {
    if (this.training_status === 'pending') {
      this.order_arr[value - 1].workout_order = this.order_arr[index].workout_order;
      this.order_arr[index].workout_order = +value;
      this.api.postChangeWorkoutOrder(this.order_arr).subscribe(res => {
        this.geTrainingWorkouts();
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }
  }


  OnNbSetsChange(value) {
    try {
      this.nbRepsPerSetArray = Array(+value).fill(0)
      this.editWorkoutForm.controls['nbRepsPerSet'].setValue(this.nbRepsPerSetArray)
    } catch (error) { }
  }
  onSetNumberChange(value) {
    this.activeSetNumberIndex = +value
  }
  onSetRepetitionNumberChange(value) {
    this.nbRepsPerSetArray[this.activeSetNumberIndex] = +value
    this.editWorkoutForm.controls['nbRepsPerSet'].setValue(this.nbRepsPerSetArray)
  }



  OnEdit(edit_content, item) {

    if (item.nbRepsPerSet) {
      this.nbRepsPerSetArray = item.nbRepsPerSet
    } else {
      this.nbRepsPerSetArray = []
    }

    this.editWorkoutForm.controls['id'].setValue(item.id);
    this.editWorkoutForm.controls['title'].setValue(item.title);
    this.editWorkoutForm.controls['setRest'].setValue(item.setRest);
    this.editWorkoutForm.controls['exerciceRest'].setValue(item.exerciceRest);
    this.editWorkoutForm.controls['nbSets'].setValue(item.nbSets);
    this.editWorkoutForm.controls['nbRepsPerSet'].setValue(item.nbRepsPerSet);
    this.editWorkoutForm.controls['workoutTime'].setValue(item.workoutTime);
    this.editWorkoutForm.controls['maximum'].setValue(item.maximum);
    this.editWorkoutForm.controls['minTime'].setValue(item.minTime);
    this.editWorkoutForm.controls['maxTime'].setValue(item.maxTime);
    this.editWorkoutForm.controls['minRepetitions'].setValue(item.minRepetitions);
    this.editWorkoutForm.controls['maxRepetitions'].setValue(item.maxRepetitions);
    this.editWorkoutForm.controls['color'].setValue(item.color);
    this.editWorkoutForm.controls['weight'].setValue(item.weight);
    this.editWorkoutForm.controls['idMedia'].setValue(item.idMedia);

    this.modalService.open(edit_content, { ariaLabelledBy: 'modal-basic-title' });
  }

  OnWorkoutTimeChange(value) {
    if (+value > 0) {
      this.editWorkoutForm.controls['workoutTime'].setValue(+value);
    }
  }
  OnMediaDropdownChanged(value) {
    this.editWorkoutForm.controls['idMedia'].setValue(+value);
  }
  OnMaxDropdownChange(value) {
    if (value === 'MAX reps' || value === 'MAX time') {

      this.editWorkoutForm.controls['maximum'].setValue(value);
      this.editWorkoutForm.controls['minTime'].setValue(0);
      this.editWorkoutForm.controls['maxTime'].setValue(0);
      this.editWorkoutForm.controls['minRepetitions'].setValue(0);
      this.editWorkoutForm.controls['maxRepetitions'].setValue(0);
    }
  }
  OnRepsChange(value) {
    if (+value > 0) {
      this.editWorkoutForm.controls['minTime'].setValue(0);
      this.editWorkoutForm.controls['maxTime'].setValue(0);
      var inputValue = (<HTMLInputElement>document.getElementById('maximum'));
      inputValue.value = '';
    }
  }
  OnTimeChange(value) {
    if (+value > 0) {
      this.editWorkoutForm.controls['minRepetitions'].setValue(0);
      this.editWorkoutForm.controls['maxRepetitions'].setValue(0);
      var inputValue = (<HTMLInputElement>document.getElementById('maximum'));
      inputValue.value = '';
    }
  }

  OnSubmit() {
    this.submitted = true;
    if (this.editWorkoutForm.valid) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'Workout will be updated', 'warning').then((result) => {
        if (result.value) {
          this.api.postUpdateWorkout(this.editWorkoutForm.value).subscribe(res => {
            this.swa.ShowAlert('Success', 'Workout updated successfully', 'success')
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }

  }

  onDeleteWorkout(id) {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Workout will be deleted', 'warning').then((result) => {
      if (result.value) {
        var data = { id: id }
        this.api.postDeleteWorkout(data).subscribe(res => {
          const index = this.data.findIndex((e) => e.id === id);
          this.data.splice(index, 1)
          this.swa.ShowAlert('Success', 'Workout deleted', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }

  open(content, src) {
    let source = '<iframe class="embed-responsive-item" src=' + src + ' allowfullscreen></iframe>'
    this.modaVideo = this.sanitizer.bypassSecurityTrustHtml(source)
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  OnClose() {
    this.modalService.dismissAll()
  }
}
