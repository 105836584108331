import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-media',
  templateUrl: './add-media.component.html',
  styleUrls: ['./add-media.component.css']
})
export class AddMediaComponent implements OnInit {


  formData = new FormData();
  fileToUpload: any;

  addMediaForm: FormGroup;
  submitted = false;

  constructor(private api: ApiService, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.addMediaForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(3)]],
      uri: null,
      type: [null, [Validators.required]],
      path: null,
    });
  }

  get f(): any { return this.addMediaForm.controls; }

  OnYoutubeLinkChange(value) {
    try {
      var video_id = value.split('v=')[1];
      var ampersandPosition = video_id.indexOf('&');
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      this.addMediaForm.controls['uri'].setValue('https://www.youtube.com/embed/' + video_id)
    } catch (err) {
      if (this.addMediaForm.get('uri').value) {
        alert('Enter valid youtube link')
      }
    }
  }

  OnDropdownChange(value) {
    this.addMediaForm.controls['type'].setValue(value)
    if (this.addMediaForm.get('type').value === 'training') {
      this.addMediaForm.controls['path'].setValue(null)
      this.formData = new FormData()
    }
  }

  OnChooseFile(e) {
    let elem = e.target;
    this.fileToUpload = elem.files[0];
  }

  OnSubmit() {
    this.submitted = true;
    if (this.addMediaForm.valid) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'Media will be added', 'warning').then((result) => {
        if (result.value) {
          if (this.addMediaForm.get('type').value === 'workout') {
            this.addMediaForm.controls['path'].setValue(
              this.addMediaForm.get('type').value + '_' + (this.addMediaForm.get('type').value).toLowerCase()
            )
            this.formData.set('attachments[]', this.fileToUpload);
          }
          this.formData.set('data', JSON.stringify(this.addMediaForm.value))

          this.api.postAddMedia(this.formData).subscribe(res => {
            this.swa.ShowAlert('Success', 'Media added successfully', 'success')
          }, err => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }
}
