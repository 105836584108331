import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-view-class',
  templateUrl: './view-class.component.html',
  styleUrls: ['./view-class.component.css']
})
export class ViewClassComponent implements OnInit {
  classForm: FormGroup;
  submitted = false;
  class: any;
  user_info: any;

  startDateTime;
  endDateTime;

  constructor(private api: ApiService, public cms: CommonService, private swa: SweetAlertService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    if (!this.cms.classesCalendarEvents) {
      this.api.getAllClasses().subscribe(res => {
        var response: any = res;
        this.cms.classesCalendarEvents = response.success
        this.getClass();
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    } else {
      this.getClass()
      this.api.showLoader = false;
    }

    this.classForm = this.formBuilder.group({
      id: null,
      title: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(30)]],
      startDate: [null, [Validators.required]],
      startDate_time: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      endDate_time: [null, [Validators.required]],
    });
  }

  get f(): any { return this.classForm.controls; }

  getClass() {
    this.route.params.subscribe(params => {
      this.class = this.cms.classesCalendarEvents.find(x => x.id === +params.id);
      if (!this.class) {
        this.router.navigateByUrl('home')
        return 0;
      }
      if (this.class.reserved === 1) {
        var data = { id: +params.id };
        this.api.postGetReservedClassUserDetials(data).subscribe(res => {
          var response: any = res;
          this.user_info = response.success
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    });

    setTimeout(() => {
      this.startDateTime = this.cms.getDateTimeJSON(this.class.start);
      this.endDateTime = this.cms.getDateTimeJSON(this.class.end);

      this.classForm.controls['id'].setValue(this.class.id);
      this.classForm.controls['title'].setValue(this.class.title);
      this.classForm.controls['startDate'].setValue(this.startDateTime.date);
      this.classForm.controls['endDate'].setValue(this.endDateTime.date);
      this.classForm.controls['startDate_time'].setValue(this.startDateTime.time);
      this.classForm.controls['endDate_time'].setValue(this.endDateTime.time);
    }, 100)

  }

  OnUpdateClass() {
    this.submitted = true;
    if (this.classForm.valid) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'Class will be added', 'warning').then((result) => {
        if (result.value) {
          if (new Date(this.startDateTime) >= new Date(this.endDateTime)) {
            this.swa.ShowAlert('Error', 'Start date must be greater than end date', 'error')
            return 0
          }
          this.class.title = this.classForm.get('title').value;
          this.class.startDate = this.cms.getDateTimeSTRING(this.classForm.get('startDate').value, this.classForm.get('startDate_time').value);
          this.class.endDate = this.cms.getDateTimeSTRING(this.classForm.get('endDate').value, this.classForm.get('endDate_time').value);
          this.api.postUpdateClass(this.class).subscribe(res => {
            window.history.back();
            this.swa.ShowAlert('Success', 'Class updated successfully', 'success')
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }

  OnDeleteClass() {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Class will be deleted', 'warning').then((result) => {
      if (result.value) {
        var data = { id: this.class.id };
        this.api.postDeleteClass(data).subscribe(res => {
          window.history.back();
          this.swa.ShowAlert('Success', 'Class deleted successfully', 'success')
        }, (err) => {
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }
}
