import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-add-workout',
  templateUrl: './add-workout.component.html',
  styleUrls: ['./add-workout.component.css']
})
export class AddWorkoutComponent implements OnInit {

  addWorkoutForm: FormGroup;
  submitted = false;
  RepsArray = new Array(100)

  nbRepsPerSetArray = [];
  activeSetNumberIndex = 0;

  workout_media: any;
  arr_workout: any = [];
  training_id;

  constructor(public cms: CommonService, private modalService: NgbModal, private route: ActivatedRoute, private api: ApiService, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.addWorkoutForm = this.formBuilder.group({
        title: [null, [Validators.required]],
        idTraining: +params['id'],
        setRest: [null, [Validators.required]],
        exerciceRest: [null, [Validators.required]],
        nbSets: [null, [Validators.required]],
        nbRepsPerSet: null,
        workoutTime: 0,
        minRepetitions: 0,
        maxRepetitions: 0,
        minTime: 0,
        maxTime: 0,
        maximum: null,
        color: null,
        weight: 0,
        idMedia: 0,
      });
      this.training_id = +params['id']
    });

    this.api.getAllWorkoutMedia().subscribe(res => {
      var response: any = res;
      this.workout_media = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  onPasteWorkouts() {
    this.arr_workout = this.cms.CopyWorkouts;
    console.log(this.cms.CopyWorkouts)
    for (let i in this.arr_workout) {
      this.arr_workout[i].idTraining = this.training_id;
      if (this.arr_workout[i].nbRepsPerSet === null) {
        this.arr_workout[i].nbRepsPerSet = JSON.stringify(Array(+this.arr_workout[i].nbSets).fill(0))
      } else {
        this.arr_workout[i].nbRepsPerSet = JSON.stringify(this.arr_workout[i].nbRepsPerSet)
      }
    }
  }

  get f(): any { return this.addWorkoutForm.controls; }

  OnMediaDropdownChanged(value) {
    this.addWorkoutForm.controls['idMedia'].setValue(+value);
  }
  OnMaxDropdownChange(value) {
    if (value === 'MAX reps' || value === 'MAX time') {

      this.addWorkoutForm.controls['maximum'].setValue(value);
      this.addWorkoutForm.controls['minTime'].setValue(0);
      this.addWorkoutForm.controls['maxTime'].setValue(0);
      this.addWorkoutForm.controls['minRepetitions'].setValue(0);
      this.addWorkoutForm.controls['maxRepetitions'].setValue(0);
    }
  }


  OnNbSetsChange(value) {
    try {
      this.nbRepsPerSetArray = Array(+value).fill(0)
      this.addWorkoutForm.controls['nbRepsPerSet'].setValue(JSON.stringify(this.nbRepsPerSetArray))
    } catch (error) { }
  }
  onSetNumberChange(value) {
    this.activeSetNumberIndex = +value
  }
  onSetRepetitionNumberChange(value) {
    this.nbRepsPerSetArray[this.activeSetNumberIndex] = +value
    this.addWorkoutForm.controls['nbRepsPerSet'].setValue(JSON.stringify(this.nbRepsPerSetArray))
  }

  OnWorkoutTimeChange(value) {
    if (+value > 0) {
      this.addWorkoutForm.controls['workoutTime'].setValue(+value);
    }
  }
  OnRepsChange(value) {
    if (+value > 0) {
      this.addWorkoutForm.controls['minTime'].setValue(0);
      this.addWorkoutForm.controls['maxTime'].setValue(0);
      var inputValue = (<HTMLInputElement>document.getElementById('maximum'));
      inputValue.value = '';
    }
  }
  OnTimeChange(value) {
    if (+value > 0) {
      this.addWorkoutForm.controls['minRepetitions'].setValue(0);
      this.addWorkoutForm.controls['maxRepetitions'].setValue(0);
      var inputValue = (<HTMLInputElement>document.getElementById('maximum'));
      inputValue.value = '';
    }
  }

  OnAddNew() {
    this.submitted = true;
    if (this.addWorkoutForm.valid) {
      this.arr_workout.push(this.addWorkoutForm.value)
      this.submitted = false
      window.scrollTo(0, 0)
    }
  }

  onRemoveWorkout(index) {
    this.arr_workout.splice(index, 1);
  }

  onCloneWorkout(index) {
    this.arr_workout.push(this.arr_workout[index])
  }

  OnSubmit() {
    if (this.arr_workout.length) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'Workout will be added', 'warning').then((result) => {
        if (result.value) {
          this.api.postAddWorkouts(this.arr_workout).subscribe(res => {
            this.arr_workout = []
            this.swa.ShowAlert('Success', 'Workout added successfully', 'success')
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }
}
