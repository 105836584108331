import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  URL = window.location.host.startsWith('localhost') ? 'http://localhost:8000/api/' : 'https://api.gravity-gym.com/api/'

  AuthToken = '';
  Is_Logged_In = new BehaviorSubject<boolean>(true);
  showLoader = false;

  getAttempt() {
    this.showLoader = true;
    return this.http.get(this.URL + 'attempt', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    })
  }

  postLogin(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'login', data);
  }

  postSignup(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'signup', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postChangeUserPasswordByAdmin(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'changeUserPasswordByAdmin', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getAllUsers() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllUsers', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postDeleteUser(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'deleteUser', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  // no enc
  postAddMedia(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addMedia', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postDeleteMedia(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'deleteMedia', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getSpecificMedia(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getSpecificMedia/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  // no enc
  postUpdateMedia(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'updateMedia', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getAllTrainingMedia() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllTrainingMedia', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getAllWorkoutMedia() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllWorkoutMedia', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getAllTrainings(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllTrainings/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getSpecificTraining(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getSpecificTraining/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postDeleteTraining(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'deleteTraining', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getTrainingWorkouts(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getTrainingWorkouts/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getHistoryTrainingWorkouts(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getHistoryTrainingWorkouts/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postChangeWorkoutOrder(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'changeWorkoutOrder', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postAddTraining(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addTraining', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postCloneTraining(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'cloneTraining', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postAddWorkouts(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addWorkouts', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postDeleteWorkout(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'deleteWorkout', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postUpdateTraining(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'updateTraining', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postUpdateWorkout(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'updateWorkout', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getAllContactUs() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllContactUs', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postDeleteContactUs(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'deleteContactUs', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getSpecificContactUs(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getSpecificContactUs/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getNutritionFitnessSpecificUser(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getNutritionFitnessSpecificUser/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getSpecificAssessments(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getSpecificAssessments/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getUserRegisteredPlans(id) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getUserRegisteredPlans/' + id, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postsendPlanConfirmation(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'sendPlanConfirmation', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postcancelPlanRequest(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'cancelPlanRequest', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getBookingUsers() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getBookingUsers', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postConfirmBooking(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'confirmBooking', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getAllClasses() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllClasses', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postAddClass(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addClass', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postUpdateClass(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'updateClass', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postDeleteClass(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'deleteClass', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postGetReservedClassUserDetials(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'getReservedClassUserDetials', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }
}
